<template>
  <div class="group-cancel" v-if="!isProcessing">
    <p class="group-cancel__text">以下のユーザーをFBグループから外してください。</p>
    <div class="group-cancel__user" v-for="user in deletedFacebookUsers" :key="user.uid">
      <div class="group-cancel__user__tag" v-if="exemption(user.uid)">
        <p class="group-cancel__user__tag__text">{{ tagName(exemption(user.uid).taid) }}</p>
      </div>
      <group-user class="group-cancel__user__detail" :user="user" :email="emails[user.uid]" />
      <div class="group-cancel__user__action">
        <v-btn class="group-cancel__user__action__btn" @click="open(user)" depressed>外した</v-btn>
      </div>
    </div>
    <confirm-dialog ref="confirm" title="FBグループから外す" :msg="dialogMsg"
                    @do-action="addFacebookGroup()" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/confirm'
import GroupUser from '@/components/common/user/detail'

export default {
  name: 'group_cancel',
  components: { ConfirmDialog, GroupUser },
  data () {
    return {
      // 退会するユーザーID
      uid: null,
      // 退会するユーザー名
      name: ''
    }
  },
  async mounted () {
    const promises = []
    // 全ユーザー情報の取得
    if (!this.hasGot) promises.push(this.$store.dispatch('users/getUsers'))
    // タグ情報の取得
    if (this.tags.length === 0) promises.push(this.$store.dispatch('tags/getTags'))
    // 例外情報の取得
    if (this.exemptions.length === 0 ) promises.push(this.$store.dispatch('exemptions/getExemptions'))
    // ユーザーのメールアドレス取得
    if (Object.keys(this.emails).length === 0) promises.push(this.$store.dispatch('functions/getAllEmails'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 全ユーザー情報を取得済みかどうか
     */
    hasGot () {
      return this.$store.getters['users/hasGot']
    },
    /**
     * @return {Object[]}タグ情報一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object} 全ユーザー情報
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Object[]} Facebookグループ未加入かつ退会していないユーザー
     */
    deletedFacebookUsers () {
      const deletedFacebookUsers = {}
      Object.keys(this.users).forEach(key => {
        const user = this.users[key]
        if (user.isFacebookMember && user.isDeleted) deletedFacebookUsers[key] = user
      })
      return deletedFacebookUsers
    },
    /**
     * @return {Object[]} 例外情報一覧
     */
    exemptions () {
      return this.$store.getters['exemptions/exemptions']
    },
    /**
     * @return {Object} ユーザーのメールアドレス一覧
     */
    emails () {
      return this.$store.getters.emails
    },
    /**
     * @return {String} 確認ダイアログに表示する文
     */
    dialogMsg () {
      return this.name + 'さんをFBグループから外した処理にします。一度処理を行うと元に戻すことができません。'
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * 例外情報を取得する
     * @param {String} uid ユーザーのID
     * @return {Object} 例外情報
     */
    exemption (uid) {
      return this.exemptions.find(exemption => exemption.uid === uid)
    },
    /**
     * タグ名を取得する
     * @param {String} taid タグのID
     * @return {String} タグ名
     */
    tagName (taid) {
      return this.$store.getters['tags/tag'](taid).name
    },
    /**
     * ダイアログを開いて、加入させるユーザーIDを設定する
     * @param {Object} user 加入させるユーザーのID
     */
    open (user) {
      this.$refs.confirm.open()
      this.uid = user.uid
      this.name = user.name
    },
    /**
     * Facebookグループ脱退処理
     */
    async addFacebookGroup () {
      this.$store.commit('setSubmitting', true)

      const params = {
        isFacebookMember: false,
        updatedAt: new Date()
      }

      await this.$store.dispatch('users/updateUser', { uid: this.uid, params: params })

      // firestoreの変更が完了してからauthと画像の削除を行う
      const promises = []

      const exemption = this.exemption(this.uid)
      if (exemption) promises.push(this.$store.dispatch('exemptions/deleteExemption', exemption.eid))
      promises.push(this.$store.dispatch('cloudstorage/deleteImage', this.users[this.uid].icon))
      promises.push(this.$store.dispatch('functions/deleteAuthUser', this.uid))

      await Promise.all(promises)

      // テロップを表示して移動
      this.$store.commit('setTelop', { show: true, msg: 'Facebookグループから外しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.group-cancel {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__text {
    font-size: 1.2rem;
  }
  &__user {
    position: relative;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    &__action {
      position: absolute;
      top: 0;
      right: $padding_width;
      min-width: 60px;
      margin-left: 10px;
      &__btn {
        margin: 0 -10px 0 auto;
        font-size: 1rem;
        color: $white_color;
        border-radius: 15px;
        &.v-btn:not(.v-btn--round).v-size--default {
          min-width: auto;
          height: auto;
          padding: 8px 16px;
        }
        &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
          background-color: $orange_color;
        }
      }
    }
    &__tag {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      min-width: 60px;
      max-width: calc((100% - 10px * 4 - 100px) / 2);
      height: 34px;
      padding: 0 15px;
      margin-left: 10px;
      border: 2px solid $orange_color;
      border-radius: 5px;
      &__text {
        margin: 0;
        overflow: hidden;
        font-size: 1.2rem;
        color: $orange_color;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: $white_color;
      }
    }
  }
  &__warning {
    margin-top: 30px;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
