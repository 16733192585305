<template>
  <div class="admin-user">
    <v-img class="admin-user__icon" :src="user.icon" />
    <p class="admin-user__name">{{ user.name }}</p>
    <p class="admin-user__email">{{ email }}</p>
  </div>
</template>

<script>
export default {
  props: {
    // ユーザー情報
    user: {
      type: Object,
      required: true
    },
    // ユーザーのメールアドレス
    email: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.admin-user {
  &__icon {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    background-color: $gray_lighten_color;
    border-radius: 50%;
  }
  &__name {
    margin: 0;
    margin-top: 10px;
    font-size: 1.4rem;
    text-align: center;
  }
  &__email {
    margin: 0;
    margin-top: 5px;
    font-size: 1.2rem;
    color: $gray_color;
    text-align: center;
  }
}
</style>
